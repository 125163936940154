
































































































import {Component, Vue} from "vue-property-decorator";
import Order, {OrderType} from "@/models/Order";
import OrderService from "@/services/OrderService";
import Product from "@/models/Product";
import ProductService from "@/services/ProductService";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import SessionModule from "@/store/SessionModule";
import ConstantTool from "@/services/tool/ConstantTool";
import LangModule from "@/store/LangModule";
import UneditableProductListComponent from "@/components/UneditableProductListComponent.vue";
import {AuthorityName} from "@/models/Authority";
import {MultipleItem, SingleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";

@Component({components:{ UneditableProductListComponent }})
export default class OrderDetailView extends Vue {

	lang: any = getModule(LangModule).lang
  order: SingleItem<Order> = { item: new Order() }
  loading: boolean = false

	headers = [
		{ text: this.lang.image, value: 'picture' },
		{ text: this.lang.reference, value: 'product.connectorId' },
		{ text: this.lang.product, value: 'name' },
		{ text: this.lang.quantity, value: 'quantity' },
		{text: this.lang.pvp, value: 'commercialRate'},
		{text: this.lang.total, value: 'pvpTaxBase'},
	]

	orderTypeFinish: OrderType = OrderType.FINISH
	orderTypeSend: OrderType = OrderType.SEND
	orderTypeWaiting: OrderType = OrderType.WAITING
	sessionModule: SessionModule = getModule(SessionModule)
	isDistributor = this.sessionModule.session.authorities.map(e => e.title == "distributor")[0]
	isManager: boolean = this.sessionModule.session.authorities[0].name == AuthorityName.MANAGER
	authority?: string = this.sessionModule.session.authorities[0].name
	pvp: boolean = ((!!this.authority) && (this.authority == AuthorityName.COMMERCIAL))


	get rateId() { return this.sessionModule.session.user.rateId }
	get isDisable(): boolean { return this.order.item.orderType != this.orderTypeWaiting }

	created()  { this.refresh() }

	async refresh() {
    try {
      await Handler.getItem(this, this.order, () =>
          OrderService.getOrder2(Number(this.$route.params.orderId))
      )
    } catch (e) {
      console.log(e)
    }
  }

	sendOrder() {
		getModule(DialogModule).showDialog(new Dialog(
			this.lang.warning,
			this.lang.sendOrderQuestion,
			() => OrderService.sendOrder(this, this.order.item.id!)
		))
	}

	cloneOrder() {
		OrderService.cloneOrder(this, this.order.item)
	}

	getOrderPdf() {
		OrderService.getOrderPdf(this, this.order.item)
	}

	//Precio del comercial
	get totalPvp() {
		let total = 0
		const values = this.order.item.orderDetails!.map(e => (e.product!.commercialRate! * e.quantity!))
		values.forEach(e => {total = total + e})
		return total
	}

	//Precio del distribuidor
	get total() {
		let total = 0
		const values = this.order.item.orderDetails!.map(e => Number(e.product!.rates![this.rateId! - 1].price) * Number(e.quantity))
		values.forEach(e => {total = total + e })
		return total
	}

	get totalQuantity(): number {
		let total = 0
		const values = this.order.item.orderDetails!.map(e => Number(e.quantity!))
		values.forEach(e => {total = total + e})
		return total
	}

}
